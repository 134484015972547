import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectContactoCliente (Vue, filter, search, sorter, page, idoperacioncrm) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idoperacioncrm', idoperacioncrm)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
        .addILike('tcontacto_desc', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, filter.fdesde.value)
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, filter.fhasta.value)
    }
    const resp = await Vue.$api.call(
      'contactoCliente.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectContactoClienteRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idcontacto_cliente', pks)
    const resp = await Vue.$api.call('contactoCliente.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
